

/* END - CSS Reset */

/* You can add global styles to this file, and also import other style files */
@import "reset.css";
@import url('@scania/tegel/dist/tegel/tegel.css');
@import 'toastr.css';


/* END - CSS Reset */
.overlay-container {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
}

.wrapper {
  overflow-y: auto;
  padding: 0px 14px 10px 14px;
  border-radius: 8px;
  border: #e7e9ee solid 0.1px;
}

.wrapper-with-only-border {
  padding: 0px 14px 0px 14px;
  border-radius: 8px;
  border: #e7e9ee solid 0.1px;
}


.rejected, .approved, .in-review, .pending-review {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  position: relative;
  padding: 7px 2px; /* Adjust padding as needed */
  border-radius: 15px;
}

.rejected::before, .approved::before, .in-review::before, .pending-review::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.rejected {
  background-color: var(--tds-negative);
  color: white; 
}

.approved {
 background-color: var(--tds-positive);
  color: white; 
}

.pending-review {
  background-color: var(--tds-warning); 
  color: white; 
}

.in-review {
  background-color: var(--tds-information); 
  color: white; 
}


#download:hover {
  transform: scale(1.2);
}

.icons:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 items in each row */
  grid-gap: 12px;
  overflow-y: auto;
  padding-bottom: 10px;
}

a {
  cursor: pointer;
}

/* stylings for drag and drop feature in tds-table */
/* Change cursor to indicate draggable rows */
tds-table-body-row[draggable="true"] {
  cursor: grab;
  transition: background-color 0.2s ease;
}

/* Change cursor to grabbing when row is dragged */
tds-table-body-row[draggable="true"]:active {
  cursor: grabbing;
}

/* Highlight the dragged row */
tds-table-body-row.dragging {
  background-color: #d1eaff; /* Light blue background to indicate dragging */
  border: 2px dashed var(--tds-information); /* Dashed border around the dragged row */
  opacity: 0.7; /* Slightly transparent to indicate movement */
}

/* Highlight the row over which the dragged row is hovering */
tds-table-body-row.over {
  border-top: 4px solid var(--tds-information); /* Thicker top border to indicate drop area */
  background-color: #f0f8ff; /* Light blue tint to indicate potential drop target */
}

/* Animate the row drop */
tds-table-body-row {
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/* Add a slight bounce effect after the row is dropped */
tds-table-body-row.drop-bounce {
  animation: bounce 0.3s ease;
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}
